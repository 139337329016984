@media (min-width: $screen-md){
    .product-thumb {
        .group-action {
            @include opacity(0);
            z-index: 10;
            button {
                position: relative;
                @include opacity(0);
            }
        } 
              
        &:hover, .product-layout:hover & {
            .group-action {
                @include opacity(1);
                button {
                    @include opacity(1);
                }
            }    
        }
    }
}

.pa_single_image {
    position: relative;
    &:before {
        @include box-shadow (0 0 0 2px rgba(255, 255, 255, 0) inset);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        @include transition( all 0.3s ease-in-out 0s);
        width: 100%;
        z-index: 2;
    }
    &:hover:before{
        @include box-shadow (0 0 0 30px rgba(255, 255, 255, 0.3) inset);
    }
}

