/**
 * Advanded search
 */
 $height : 45px !default;

 #pav-adv-search {
 	position: relative;
 	select {
 		display: none;
 	}
 	.select2-container .select2-selection--single {
 		height:  $height ;
 		border: solid 1px $border-color;
 		border-top-width: 0;
 		border-bottom-width: 0;
 		border-radius: 0;

 	}
 	.select2-container--default .select2-selection--single .select2-selection__arrow {
 		height:  $height ;
 		b {
 			top: 20px;
 			margin: 0;
 			right: 10px;
 			left: auto;
 		}
 	}
 	.select2-container--default .select2-selection--single .select2-selection__rendered {
 		line-height:  $height ;
 	}
 	.categories-selection{
 		width: 200px;
 		position: absolute;
 		top: 0;
 		right:  $height ;
 	}
 	input {
 		padding-right: 265px;
 		border:0;
 		background: #fff;
 		border-radius: 0;
 		height:  $height;
 	}
 	button {
 		position: absolute;
 		top: 0;
 		right: 0;
 		width: 50px;
 		border-radius: 0;
 		border:0;
 		height: $height;
 		padding: 0;
 		@extend .btn-primary;
 	}
 }
 .pa_quicklogin_element_wrapper {
 	display: inline-block;
 	    vertical-align: top;
 	+ .pa_minicart_element_wrapper {
 		display: inline-block;
 		margin-left: 10px;
 	}
 }
 .pa_languages_element_wrapper
 + .pa_currencies_element_wrapper .form-currency {
 	margin-left: 20px;
 }
 /**
 * PopUp Search
 */
 .pavo-popup-search {

 	a {
 		&.btn {
			background: transparent;
			font-size: 16px;
			border: 0;
			padding: 15px;
			&:active:focus {
				background: transparent;
			}
		}
		&.btn-search-default {
			&:hover, &:active {
				color: $theme-color;
			}
		}
		&.btn-default {
			color: $white;
			&:hover, &:active {
				color: $theme-color;
			}
		}
		&.btn-primary {
			color: $primary;
			&:hover, &:active {
				color: darken($primary, 10%);
			}
		}
		&.btn-info {
			color: $info;
			&:hover, &:active {
				color: darken($info, 10%);
			}
		}
		&.btn-warning {
			color: $warning;
			&:hover, &:active {
				color: darken($warning, 10%);
			}
		}
		&.btn-success {
			color: $success;
			&:hover, &:active {
				color: darken($success, 10%);
			}
		}
		&.btn-danger {
			color: $danger;
			&:hover, &:active {
				color: darken($danger, 10%);
			}
		}
 	}
}