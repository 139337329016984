body{
    overflow-x: hidden;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 30px;
}

#page {
    position: relative;
    overflow: hidden;
}
#pavo-sticky-header{
    position:fixed;
    background:#FFF;
    width: 100%;
    z-index: 1000;
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $text-color;
    background-color: #fff;
    .mainmenu .navbar {
        background: #FFF;
        margin-bottom: 0;
        border: 0;
        text-align: center;
    }
    .navbar-nav > li > a {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .verticalmenu {
        > li > a {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    .mainmenu .navbar .navbar-nav > li.parent > a:before {
        top: 20px;
    }
    @media (max-width: $screen-md-max) {
        .pav-mainmenu .navbar-nav > li > a {
            padding-right: 25px;
            padding-left: 25px;
        }

    }
    -webkit-box-shadow: 2px -4px 12px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px -4px 12px -1px rgba(0,0,0,0.75);
    box-shadow: 2px -4px 12px -1px rgba(0,0,0,0.75);
    .logo {
        margin-top: 10px;
        img{
            max-height: 40px;
        }
    }
    .mini-cart {
        margin-top: 15px;
        &.minicart-style-2 {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

/*** TOP BAR ***/
.topbar {
    background: $topbar-bg;
    color: $topbar-color;
    border-bottom: 1px solid $border-color;
    border-top: 5px solid $black;
    padding: 5px 0;
    width: 100%;
    float:left;
    .pull-left {
        margin-right: 20px;
    }
    li.dropdown {
        @extend .effect-dropdown-menu;
    }
}
.pav-mobile-nav .inner {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    +.inner {
        padding-bottom: 30px;
    }
}

/****HEADER****/

.header-main {
    text-align: center;
    #logo {
        padding: 30px 0;
    }
}
.header-bottom {
    .header-buttom-menu {
        border-top: 1px solid $border-color;
        border-bottom: 2px solid $black;
    } 
}
/*** Language, currency ***/
.form-currency, .form-language {
    .btn-group {
        @extend .effect-dropdown-menu;
    }
    .btn-link {
        padding-left:10px;
        text-transform: none;
        letter-spacing: 0;
        border-color: transparent;
        font-weight: 400;
        text-align: left;
        display: inline-block;
        width: auto;
        font-size:14px;
        color: $theme-color-default;
        &:hover, &:focus {
            color: $theme-color;
            border-color: transparent;
        }
        span {
            color: $topbar-link-color;
            &:hover {
                color: $topbar-link-hover-color;
            }
        }
    }
    .dropdown-menu .btn-link {
        color: $text-color;
        &:hover {
            color: $theme-color;
        }
    }

    .btn-group img {
        vertical-align: initial;
    }
}

.currency-select, .language-select {
    @extend .effect-menu;
    &:hover {
        color: $theme-color;
    }
}

.pa_toplinks_element {
    a {
        color: $topbar-link-color;
        &:hover {
            color: $topbar-link-hover-color;
        }
        .fa {
            color: $topbar-icon-color;
        }
    }
}
/*** pavo-quicklogin ***/

.pavo-widget-quicklogin {
    &.style-icon {
        .fa {
            font-size: 23px;
        }
    }
    .btn-link {
        color: $theme-color-default;
        border: 0;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 400;
        font-size: 14px;
        &:hover, &:focus {
            color: $theme-color;
            border: 0;
        }
    }
    a {
        vertical-align: middle;
        display: inline-block;
        padding: 0;
        line-height: normal;
    }
    .quicklogin-head > span {
        text-transform: uppercase;
        color: #fff;
    }
    .dropdown-menu {
        padding: 20px;
        border: 0;
        left: auto;
        right: 0;
        background: $theme-color-default;
        min-width: 300px;
        @include box-shadow(0 0 3px rgba(0,0,0,.15));
        label {
            font-weight: 400;
            color: $theme-color-default;
        }
        strong {
            text-transform: uppercase;
        }
        input[type="submit"] {
            width: 100%;
        }
        a {
            color: $text-color;
            @extend .effect-menu;
            &:hover {
                color: $theme-color;
            }
        }
        ul {
            @extend .list-unstyled;
        }
        .btn-forgotten {
            margin-top: 10px;
            display: block;
            color: #E15858;
        }
    }
}

/*** Search ***/
.quick-search-form {
    position: relative;
    overflow: hidden;
    border:1px solid $border-color;
    .form-control {
        padding:0 5px;
        border:0;
        height:30px;
    }
    button {
       position: absolute;
       right: 0;
       top:-6px;
       z-index: 10;
       background:$white;
       color: $theme-color-default;
       font-size: 12px;
       &:hover, &:active:hover {
           color: $theme-color;
           background: transparent;
       }
    }
}

.pavo-popup-search {
    .close {
        cursor: pointer;
        z-index: 100;
        &:hover {
            color: $theme-color-default;
            @include opacity(1);
        }
    }
    button {
        height: 70px;
    }
    .form-control {
        font-size: 32px;
        font-weight: 600;
        height: 70px;
        padding: 0 50px;
        line-height: 70px;
        @include border-radius(50px);
        &::-webkit-input-placeholder { // Chrome/Opera/Safari
            font-size: 32px;
            font-weight: 600;
        }
        &::-moz-placeholder { // Firefox 19+
            font-size: 32px;
            font-weight: 600;
        }
        &:-ms-input-placeholder { // IE 10+
            font-size: 32px;
            font-weight: 600;
        }
        &:-moz-placeholder { //Firefox 18-
            font-size: 32px;
            font-weight: 600;
        }
    }
}
/***SHOPPING CART***/

 /* mini cart style 1 */
.mini-cart {
    background: none;
    border: none;
    margin-left: 10px;
    > button {
        padding: 0;
        display: flex;
        align-items: center;
        .fa {
            background: $theme-color;
            color: $white;
            padding: 7px;
            margin-right: 5px;
        }
        &:hover {
            color: $theme-color;
        }
    }

    &.btn-group.open .dropdown-toggle {
        @include box-shadow(none);
    }
    &.minicart-style-default{
        .cart-inner{
            display: inline-block;
            text-align: left;
            color: $text-color;
            .cart-head{
                font-size: 12px;
                font-weight: 400;
                text-transform:uppercase;
            }
        }
    }
    &.minicart-style-1{
        background: lighten( $header-bg , 10% );
       .cart-icon {
            position: relative;
            color: $black;
            .cart-number{
                position: absolute;
                right: -5px;
                top: -5px;
                background: $theme-color;
                color:#fff;
                font-size: 10px;
                display: block;
                width: 22px;
                height: 22px;
                line-height: 20px;
                border-radius: 50%;
                border: solid 1px $theme-color;
            }
        }
        .cart-inner{
            display: inline-block;
            text-align: left;
            margin-left: 12px;
            color: $black;
            .cart-head{
                font-size: 12px;
                font-weight: 400;

            }
        }
    }
    &.minicart-style-2{
        background: transparent;
       .cart-icon {
            position: relative;
            .cart-number{
                position: absolute;
                right: -5px;
                top: -5px;
                background: $theme-color;
                color:#FFF;
                font-size: 10px;
                display: block;
                width: 22px;
                height: 22px;
                line-height: 20px;
                border-radius: 50%;
                border: solid 1px #FFF;
            }
            height: 48px;
            width: 48px;
            background: $theme-color-second ;
            display: flex;
            align-items: center;
            justify-content:center;
            color:#FFF;
            border-radius: 50%;
        }
    }
    &.minicart-style-3 {
        @extend .effect-dropdown-menu;
        > button {
            @include box-shadow(none);
            position: relative;
            color: $theme-color-default;
            font-size: 14px;
            &:hover {
                color: $theme-color;
            }
            > *{
                @include inline-block();
                vertical-align: middle;
            }
        }
        .cart-icon {
            position: relative;
            .cart-number{
                position: absolute;
                right: -10px;
                top: -5px;
                background: $theme-color;
                color:#FFF;
                font-size: 10px;
                display: block;
                width: 22px;
                height: 22px;
                line-height: 20px;
                border-radius: 50%;
                border: solid 1px #FFF;
            }
        }
    }
    .dropdown-menu {
        background: #fff;
        min-width: 300px;
        padding: 20px;
        left: auto;
        right: 0;
        .table-responsive01 {
            width: 100%;
            margin-right:10px;
            max-height:300px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.pav-mobile-nav .cart-number{
    position: absolute;
    right: -10px;
    top: -5px;
    background: $theme-color;
    color:#FFF;
    font-size: 10px;
    display: block;
    width: 22px;
    height: 22px;
    line-height: 20px;
    border-radius: 50%;
    border: solid 1px #FFF;
}
/* Breadcrumb */
ul.breadcrumb {
    background: $breadcrumb-bg;
    padding: 25px 0;
    text-align: left;
    position: relative;
    z-index: 10;
    @extend .list-unstyled;
    h1, h2 {
        font-size: 24px;
        margin-top: 0;
        color: $theme-color-default;
        letter-spacing: 2px;
        display: block;
        text-transform: uppercase;
    }
     li {
        display: inline-block;
        + li:before {
            content: $breadcrumb-separator;
            padding: 0 5px;
            color: $text-color;
        }
        &:last-child a {
            color: $breadcrumb-active-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
    a, i {
        color: $breadcrumb-color;
        &:hover {
            color: $theme-color;
        }
    }
    &.breadcrumb-right {
        min-height: 0;
        background: #fff;
        padding: 0;
        margin-bottom: 30px;
        &:after {
            content: none;
        }
    }
    &.breadcrumb-v2 {
        padding:5px $grid-gutter-width/2;
        margin-left: auto;
        margin-right: auto;
        @extend .container;
        //min-height: 0px;
        &:after {
            display: block;
        }
    }
}
div.breadcrumb {
    background:url(#{$image-theme-path}breadcrumb-bg.jpg) no-repeat;
}

/*** alert ***/
.alert {
    position: absolute;
    z-index: 9999999!important;
    top: 20px;
    left: auto!important;
    right: 20px;
    .close {
        @include opacity(1);
    }
}
.alert-info {
    background-color: $brand-info;
    border-color: rgba($brand-info, 0.3);
    a{
        color: darken( $brand-info, 70% );
    }
}

.alert-warning {
    background-color: $brand-warning;
    border-color: rgba($brand-warning, 0.3);
    a{
        color: darken( $brand-warning, 70% );
    }
}

.alert-danger {
    background-color: $brand-danger;
    border-color: rgba($brand-danger, 0.3);
    a{
        color: darken( $brand-danger, 70% );
    }
}
.alert-success, .alert-minimalist {
    background-color: $brand-success;
    border-color: rgba($brand-success, 0.3);
    a{
        color: darken( $brand-success, 70% );
    }
}
.alert-success, .alert-info, .alert-warning, .alert-danger, .alert-minimalist {
    border-radius: 3px;
    color: $white;
    padding: 30px 50px;
    font-weight: bold;
    border-left: 15px solid rgb(255, 240, 106);
    font-size: $font-size-base + 1;
    -webkit-box-shadow: -1px -1px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px -1px 9px 0px rgba(0,0,0,0.75);
    box-shadow: -1px -1px 9px 0px rgba(0,0,0,0.75);
    .fa {
        margin-right: .3em;
        padding-top: 4px;
    }
}
.alert-minimalist {
    background-color: $brand-success;
    border-color: rgba(149, 149, 149, 0.3);
    border-radius: 3px;
    color: $white;
    padding: 30px 50px;
    font-weight: bold;
    border-left: 15px solid rgb(255, 240, 106);
    font-size: $font-size-base + 1;
    -webkit-box-shadow: -1px -1px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px -1px 9px 0px rgba(0,0,0,0.75);
    box-shadow: -1px -1px 9px 0px rgba(0,0,0,0.75);
    a{
        color: darken( $brand-success, 70% );
    }
    .fa {
        margin-right: .3em;
        padding-top: 4px;
    }
}

.alert-minimalist > [data-notify="icon"] {
    height: 50px;
    margin-right: 12px;
}
.alert-minimalist > [data-notify="title"] {
    color: rgb(51, 51, 51);
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}
.alert-minimalist > [data-notify="message"] {
    font-size: 80%;
}

/* Layout */

#common-home + footer {
    margin-top: 0;
}
/* FOOTER */
footer.pavo-footer {
    margin-top: 60px;
    color: $footer-color;
    font-size: $footer-font-size;
    background: $footer-bg;
   
    h5{
        color: $footer-heading-color!important;
        font-size: $footer-heading-font-size;
        @extend .panel-title;
    }
    a{
        color: $footer-link-color;
        &:hover {
            color: $theme-color;
        }
    }
    address {
        color: $theme-color;
        i{
            color: $theme-color;
        }
        span{
            color: $theme-color;
            font-weight: 600;
        }
        p{
            color: $theme-color-second;
            line-height: 2.5714;
        }
    }
    > .container {
        padding: $footer-padding;
        border-top: 2px solid $black;
    }
}

.footer {
    h5 {
        @extend .panel-title;
    }
    a{
        color: $theme-color-second;
        &:hover {
            color: $theme-color;
        }
    }
    .list-unstyled {
        li {
            &:after {
                content: "\f178";
                font-family: 'FontAwesome';
                display: inline-block;
                position: absolute;
                right: 30px;
                line-height: 28px;
                font-style: normal;
                font-weight: normal;
                color: #000000;
                @include transition( all 0.3s ease-in-out 0s);
            }
            &:hover {
                &:after{
                    right:20px;
                }
            }
        }
    }
}

.footer-blockbuilder {
    margin-top: 60px;
    .pav-builder + & {
        margin-top: 0;
    }
    .breadcrumb + div + & {
        margin-top: 60px;
    }
    .pavo-widget-heading {
        margin-top: 0;
        margin-bottom: 0;

        &.nostyle {
            &:after { display:none;}
            h3 { text-align:left; padding-left:0; padding-right:0; display:block;}
        }
    }
    ul, ol {
        margin-bottom: 0;
    }
    .border-top {
        .content-heading { padding-top: 30px; font-weight: 500;}
    }

}

/* POWER BY */
.powered {
    background: $copyright-bg;
    color: $copyright-color;
    text-align: center;
    font-size: 14px;
    > .container {
        border-bottom: 10px solid $black;
        border-top: 1px solid $border-color;
        padding-top: $copyright-padding-top;
        padding-bottom: $copyright-padding-bottom;
    }
    a{
        color: $copyright-link-color;
        &:hover{
            color: $theme-color;
        }
    }

}
/***
**** layout widget *****
****/
.panel {
    border: 0;
    background: transparent;
    margin-bottom: 0px;
    @include box-shadow (none);
}
.panel-heading {
    padding: 0;
}
.panel-body {
    padding: 0;
}
.panel-default > .panel-heading {
    background: transparent;
    border: 0;
}
.panel-title {
    text-transform: uppercase;
    color: $theme-color-default;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    letter-spacing: 2px;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
    border: 0;
}

.box {
    margin-bottom: 20px;
    .box-heading,
    h3.box-heading {
        background: $theme-color;
        display: block;
        padding: 11px 25px 5px;
        color: $white !important;
        font-size: 14px;
        letter-spacing: 0px;
        position: relative;
        margin-top:0;
        &:before {
            color: $white;
            font-size: $font-size-base - 1;
            content: "\f0c9";
            display: inline-block;
            font-family: 'FontAwesome';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            position: absolute;
            left: 10px;
            top: 10px;
        }
        &:after {
            color: $white;
            font-size:$font-size-base - 1;
            content: "\f107";
            display: inline-block;
            font-family: 'FontAwesome';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            position: absolute;
            right: 20px;
            top: 10px;
        }
    }
    .box-content {

    }
}

.carousel-tabs {
    padding-top: 30px;
    padding-bottom: 50px;
}

.html-item {
    h2 {
        @extend .panel-title;
    }
}

.icon-box {
    .html-item {
        .html-heading {
            display: none;
        }
    }
}

#checkout-checkout, #checkout-cart {
    .panel-title {
        font-size: $font-size-base;
        color: $headings-color;
        font-weight: 500;
        text-transform: uppercase;
    }
    .input-group-btn {
        >button {
            padding: 0;
            width: 40px;
            height: $input-height-base;
        }
    }
    .form-group .select2-container { border: 1px solid $border-color;} 
}

.select2-container--default .select2-selection--single .select2-selection__arrow { height: auto;}
.category-image {
    padding-bottom: 10px;
}

#column-right, #column-left {
    .product-layout {
        width: 100%;
    }
    .product-label, .pav-quickview, .group-action, button {
        @extend .hide;
    }
    .product-thumb {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        align-items: flex-start;
    }
    .image {
        width: 40%;
    }
    .product-meta {
        width: 60%;
        padding: 0;
    }
}
