@import "modules/blocks-builder";
@import "modules/header-builder";

.pavlayerednavigation {
	.list-group-item {
		border-top: 1px solid $border-color;
	}
    a.list-group-item {
        text-transform: uppercase;
        color: $black;
        padding:0;
    }
    .filter-selection {
        background-color: transparent;
        .list-group-content {
            padding: 0;
            .list-group-item-choosed {
                font-weight: 400;
                padding:0 10px;
            }
        }
        .clear-all {
            display: inline-block;
            @extend .btn-link;
            margin:0 10px;
        }
    }
    
    .filter-by-price {
        .irs-from, .irs-to, .irs-single {
            background: $gray-light;
            &:after {
                border-top-color: $gray-light;
            }
        }
    }
    .irs-line-mid, .irs-line-left, 
    .irs-line-right, .irs-bar-edge {
        background: $theme-color-default;
    }

    .irs-slider { background: $gray-lighter;}

    .irs-bar {  background: $gray-light;}

    .pav-slider a span {
    	margin-left: 20px;
    }
    .checkbox label {
        &:hover {
            color: $gray-light;
        }
    }
}

// NewLestter popup
.formNewLestter  {
	&.default {
		background:#f5f5f5 url("#{$image-theme-path}default/email.png") no-repeat 91px -106px;
		border-top: 1px solid $black;	
		margin-top: 12px;
		text-align: center;
		padding: 30px 20px 0 20px;
		.description-top {
			h4 { text-transform:uppercase;}
		}
		.button-submit { margin-top: 10px;}
	}
	&.white-popup {	
		background:#e2e2e2 url("#{$image-theme-path}popup-newsletter.jpg") no-repeat right bottom;	
		color: $black;
		.description-top {
			h4 {
			 	text-transform: uppercase;
			 	letter-spacing:2px;
			 	font-family:$headings-font-family;
			}
			h5 {
				font-size: 30px;
				text-transform: uppercase;
				letter-spacing:3px;
				font-family:$headings-font-family;
				font-weight: 700;
			}
		}
		.inner {
			padding: 80px 300px 80px 50px;
			@extend .text-center;
		}
		.form-control {
			height:43px;
			@extend .text-center;
		}
		.mfp-close {
			top: -12px;
			right:-10px;
			line-height: 24px;
			padding: 0;
			font-size: 18px;
			@include size(24px,24px);
			@include opacity(1);
			@extend .btn-default;
		}
		.button-submit {
			.btn-link {
				padding: 10px 20px;
				@extend .btn-theme-default;
			}
		}
	}
}

//Blog
.lates-tblogs {
	.panel-heading {
		.panel-title {
			position: relative;
			text-align: center;
			&:after {
				background: $black;
				content: "";
				width: 100%; 
				height: 1px;
				top: 50%;
				left:0;
				position: absolute;
			}
			span {
				background: $white;
				padding: 10px 30px;
				z-index:99;
				position: relative;

			}
		}
	}
	.swiper-button-prev, .swiper-button-next {
		border:2px solid $border-color !important;
		visibility: hidden;
		@include transition( all 0.3s ease-in-out 0s);
	}
	&:hover {
		.swiper-button-prev, .swiper-button-next {
			visibility: visible;
			background: rgba(255,255,255,0.8);
			@include opacity(1);
		}
	}	
}

.hidden-image {
	> .image {
		display:none;
	}
}

.pavoblog-posts.archive {
	.row:first-child {
		display: flex;
		flex-flow: row wrap;
	}
}
.pavoblog-post .entry-content .entry-title, 
.pavoblog-post .entry-content .entry-title a, 
.pavoblog-posts .entry-content .entry-title, 
.pavoblog-posts .entry-content .entry-title a {
	height: auto;
	font-size:18px !important;

}
.pavoblog-post, .pavoblog-posts {
	padding: 0;
	line-height: 24px;
	.entry-post-gallery {
		.entry-media {
			position: relative;
		}
	}
}
.pavoblog-post h3, 
.pavoblog-post h3>*, 
.pavoblog-posts h3, 
.pavoblog-posts h3>* {
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
}
.pavoblog-post h1, 
.pavoblog-post h1>*, 
.pavoblog-posts h1, 
.pavoblog-posts h1>* {
	font-size: 14px;
	font-weight: 400;
	text-transform: none;
	margin: 20px 0;
}
.pavblog-comments {
	a {
		float: left;
    	padding-right: 20px;
	}
	.comment {
		color: $theme-color-default;
		line-height: 20px;
		margin-bottom: 10px;
	}
	.pav-comment + .pav-comment {
		margin-top: 20px;
	}
}
#pavoblog-comments {		
	margin-top: 60px;
	margin-bottom: 60px;
	h3 {
		@extend .panel-title;
	}
	.form-group {
		margin-bottom: 30px;
	}
	.comment-list {
		overflow: hidden;
		.comment {
			border-top: 0;
			overflow: hidden;
			+ li {
				border-top: 1px solid $border-color;
			}			
			.avatar {
				@include border-radius(0);				
			}
			img {
				@include border-radius(50%);	
			}
		}
		.comment-date {
			color: $theme-color-second;
		}
	}
}
.add-comment {
	background: #efefef;
}
.add-comment, .pav-comment-list {
	margin-left: -100vw;
	margin-right: -100vw;		
	.inner {
		@extend .container;
		padding-top: 60px;	
		padding-bottom: 60px;
	}
}
.pav-comment-list .inner {
	border-top: 1px solid $border-color;
}
.related_posts {
	border-top: 1px solid $border-color;
	padding-top: 60px;
	margin-top: 60px;
}
.single {
	.entry-content p {
		margin-bottom: 20px;
	}
}

.blogs-thumb {
	color: $theme-color-default;
	line-height: 24px;
	margin-bottom: 30px;
	position: relative;
	@extend .effect-v10;
	.image {
		overflow: hidden;
	}
	.blog-title {
		font-size: 16px;
		line-height: 20px;
		margin:10px 0;
		font-family: $font-family-base;
		a {
			font-size: 16px;
			font-weight: 700;
			&:hover, &:focus {
				color: $theme-color-second;
			}
		}
	}	
	.blog-meta {
		font-size: 13px;
		line-height: 12px;
		margin: 15px 0 0;
		color: $theme-color-second;
		font-weight: 400;
		font-style: italic;
		font-family:$font-family-base;

	}
	.blog-username {
		color: $theme-color-second;	
		font-style: italic;
		font-family:$font-family-base;
	}		
	.blog-body {
		background: #fff;	
	}
	.entry-category {
		margin-left: 10px;
	}
	.post-content, 
	.btn-readmore {
		.btn-link {
			font-size: $font-size-base - 2;
			font-weight:400;
		}
	}
	&.style-list {
		
		.blog-body {
			padding: 40px 30px;
		}
		.btn-readmore {
			margin-top: 50px;
		}
		.post-content {
			margin-top: 15px;
		}
		.post-content, .btn-readmore {
			display: block!important;
		}
	}
}

.pavo-widget-tabs-productcarousel {
	.nav-tabs {
		border:0;
		text-align:center;
		position: relative;
		&:after {
			background: $black;
			content: "";
			width: 100%; height: 1px;
			top: 50%;
			position: absolute;
		}
		li {
			float:none;
			display: inline-block;
			margin:0; padding:0 10px;
			background: $white;
			z-index:99;
			&:first-child{
				padding-left:30px;
			}
			a {
				position: relative;
				border:0;
				padding:10px 0;
				letter-spacing:2px;
				text-transform:uppercase;
				color: $theme-color-second;
				&:before {
				    font-family: FontAwesome;
				    position: absolute;
				    content: '\f111';
				    font-size: 6px;
				    left: -14px;
				    top: 15px;
				}
				&:hover, &:focus {
					border:0;
					color: $theme-color;
				}
				
			}
			&.active a {
				color: $theme-color;
			}
		}
	}
	.tab-content {
		padding-top: 40px;
		position: relative;
		overflow:hidden;
		.swiper-container {
			overflow: visible;
		}
		.swiper-pager {
			position: absolute;
			top:-40px;
			margin:0 auto;
			left:0; right:0;
			text-align:center;
			z-index:999;
			width: 180px;
			.swiper-button-next,
			.swiper-button-prev {
				font-size: 11px;
				font-style: italic;
				color: $white;
				height: 19px;
				width: 60px;
				margin: 0 17px;
				line-height: 18px;
				opacity: 1;
				border:0 !important;
				border-radius:0!important;
				&:before {
					display:none;
				}
			}
			.swiper-button-prev  {
				background: url("#{$image-theme-path}default/icon-prev.png") no-repeat right top;
				&:hover {
					background: url("#{$image-theme-path}default/icon-prev-hover.png") no-repeat right top;
				}
			}
			.swiper-button-next  {
				background: url("#{$image-theme-path}default/icon-next.png") no-repeat left top;
				&:hover {
					background: url("#{$image-theme-path}default/icon-next-hover.png") no-repeat left top;
				}
			}
		}
	}	
}


/**
 * Header Block 
 */
.pavo-header-builder {
    position:relative;
    &.style-absolute {
        position:absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 100;
        .mainmenu .navbar .navbar-nav > li > a {
        	color: #fff;
        	&:hover {
        		color: $theme-color;
        	}
        }
    }
    .pa-column-container, .pavo-widget-megamenu, .mainmenu .navbar {
    	position:static;
    }
    
}

.pa-google-map{
	min-height: 300px;
}
.pac-container{
	&.pac-logo{
		z-index: 99999;
	}
}

/**
 *
 */
 
.pavo-widget-team{
    border: 1px solid transparent !important;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    &.layout-1{
        text-align: center;
        .content-socials{
            opacity: 0;
            -moz-transition: all 0.5s;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -ms-transition: all 0.5s;
            position: absolute;
            top: 40%;
            left: 0;
            width: 100%;
        }
    }
    &.layout-3{
    	.content-title {
    		color: #fff;
    	}
        .content-overlay{
            text-align: center;
            width: 100%;
            height: 100%;
            padding-top: 30px;
            opacity: 0;
            transition: all .3s ease 0s;
            transform: translateY(100%);
            background: rgba(0,0,0,.4);
            position: absolute;
            top: 0;
            bottom: 0;
            color: $white;
        }
        &:hover{
            .content-overlay{
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    .content-title {   
		margin: 0px !important;
	    font-size: 15px !important;
	    text-transform: capitalize !important;
	    font-weight: 700;
	    padding-top: 15px;
	    padding-bottom: 5px;
        color: #363a42;
    }
    .content-subtitle {
        margin-bottom: 0px !important;
    }
    .content-desc {
        margin: 0 0 20px;
        float: left;
        font-size: 13px;
        width: 100%;
        color: #707070;
    	text-transform: capitalize;
    }
    &:hover{
        .content-socials{
            opacity: 1;
        }
    }
    &:hover{
        .content-image{
            &:before{
                opacity: 1;
            }
        }
    }
}

/* social wiget */
.social-network{
	.size-small a  { font-size:20px !important; }
	.size-medium a { font-size:30px !important; }
	.size-large a  { font-size:40px !important; }
	&.style-light a { color:#FFF !important;  &:hover { color:$brand-primary !important } }
	&.style-dark a { color:#000 !important; &:hover { color:$brand-primary !important } }
    .social{
    	margin-bottom: 0px !important;
        a {
            background-color: transparent;
            display: block;
            width: 30px; height: 30px;
            line-height:20px !important;
            text-align: center;
            border:1px solid $black;
            @include transition( all 0.3s ease-in-out 0s);
            .fa {
            	font-size: 12px !important;
            	color: $black;
            }
            &:hover {
            	@include border-radius(50%);
            	background: $theme-color;
            	.fa {
            		color:$white;
            	}
            }
        }
        li {
        	margin: 0 5px;
        	padding-left: 0px !important;
        	padding-right: 0px !important;
        }
    }
}

/**
 * Heading
 */
.pavo-widget-heading{
	&.style-center{
		text-align: center;
	}
	&.style-left{
		text-align: left;
	}
	&.style-right{
		text-align: right;
	}
}


/** Product deals**/
.product-timing-deal {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    letter-spacing: 2px;
    .unit {
    	font-size: 12px;
    	line-height: 12px;
    	display: block;
    }
    .time-group {
    	display: inline-block;
        background: $theme-color;
        color: #fff;
        padding: 5px 0;
        text-align: center;
    }
}


/**
 * Countdown
 */
.pavo-widget-countdown{
	.inner{
		padding:20px 10px;
		text-align: center;
	}
 
	.time-group{
		display: inline-block;
		font-size: 50px;
		text-align: center;
		font-weight: bold;
		padding:10px 20px;
		background: $primary;
		color:white;
		margin: 0 10px;
	    .unit{
			font-size: 11px;
			display: block;
			clear: both;
			padding-top:10px;
			padding-bottom: 10px;
		}
	}
	&.layout-1{
		.time-group{
			background: $info;
			.unit {
				background: #FFF;
				color: $info;
				margin-left: -20px;
				margin-right:-20px;
				margin-bottom: -10px;
			}
		}
	}
	&.layout-2{
		.time-group{
			background: inherit;
			color: #000;
			.unit {
				color: #999; 
				display: inline-block;
			}
		}
	}
	&.size-sm {
		.inner {
			padding: 5px 10px;
		}
		.time-group {
			font-size:$font-size-base + 4;
			padding: 5px 4px;
		}
	}
} 

/***/
.pavo-widget-counter {
	.counter-group {
		span { 
			display: block;
			text-align: center;
			&.counter-value {
				font-weight: 600;
				font-size: 44px;
				margin-bottom: 10px;
			}
			&.counter-label { 
				text-transform: uppercase;
				font-size: 18px;
			}
		}
	}
	&.no-color {
		.counter-value, .counter-label {
			color: #fff;
		}
	}
}
