@import url('https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i');

@font-face {
    font-family: 'brandon';
    src: url('#{$fonts-theme-path}brandon_reg-webfont.eot');
    src: url('#{$fonts-theme-path}brandon_reg-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-theme-path}brandon_reg-webfont.woff') format('woff'),
         url('#{$fonts-theme-path}brandon_reg-webfont.ttf') format('truetype'),
         url('#{$fonts-theme-path}brandon_reg-webfont.svg#brandon') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'brandon_light';
    src: url('#{$fonts-theme-path}brandon_light-webfont.eot');
    src: url('#{$fonts-theme-path}brandon_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-theme-path}brandon_light-webfont.woff') format('woff'),
         url('#{$fonts-theme-path}brandon_light-webfont.ttf') format('truetype'),
         url('#{$fonts-theme-path}brandon_light-webfont.svg#brandon_grotesquelight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'brandon_bold';
    src: url('#{$fonts-theme-path}brandon_bld-webfont.eot');
    src: url('#{$fonts-theme-path}brandon_bld-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-theme-path}brandon_bld-webfont.woff') format('woff'),
         url('#{$fonts-theme-path}brandon_bld-webfont.ttf') format('truetype'),
         url('#{$fonts-theme-path}brandon_bld-webfont.svg#brandon_grotesquebold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'brandon_black';
    src: url('#{$fonts-theme-path}brandon_blk-webfont.eot');
    src: url('#{$fonts-theme-path}brandon_blk-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-theme-path}brandon_blk-webfont.woff2') format('woff2'),
         url('#{$fonts-theme-path}brandon_blk-webfont.woff') format('woff'),
         url('#{$fonts-theme-path}brandon_blk-webfont.ttf') format('truetype'),
         url('#{$fonts-theme-path}brandon_blk-webfont.svg#brandon_grotesqueblack') format('svg');
    font-weight: normal;
    font-style: normal;
}