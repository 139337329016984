.product-thumb {
    text-align: center;
    position: relative;
    background-color: $white;
    margin-bottom: 30px;
    h4 {
        font-weight: $product-name-font-weight;
        margin: 0px;
        line-height: 18px;
        display: inline-block;
        a{
            color: $product-name-color;
            &:hover{
                color: $product-name-hover-color;
            }
        }
    }
    .image {
        position: relative;
        text-align: center;
        z-index: 0;
        margin: 0 auto;
        overflow: hidden;
    }
    .group-action {
        display: flex;
        position: absolute;
        left: 0; right:0;
        bottom: 0;
        border-bottom: 1px solid $border-color;
        background: $white;
        @include transition (all .35s ease 0s);
        button {
            background: transparent; 
            color: $text-color;
            margin:0;
            padding:0;
            height:56px;
            line-height:56px;
            text-transform:uppercase;
            text-align: center;
            .fa {
                color: $theme-color-second;
            }
            &:after {
                position:absolute;
                content:"";
                height: 100%;
                width:1px;
                right:0;
                top:0;
                border-right: 1px solid $border-color;
            }
            &:last-child {
                &:after {
                    border-right:0;
                }
            }
            &:hover, &:focus {
                background: $gray-lighter;
            }
            &.btn-cart {
                width: 50%;
                font-size: $font-size-base - 2;
                .fa { padding-right: 5px;}
            }
            &.btn-wishlist,
            &.btn-compare {
                width: 25%;
            }
        }
    }
    .caption {
        position: relative;
        z-index: 12;
        background: #fff;
    }
    .product-meta {
        padding: 20px 0;
        position: relative;
    } 
    
    .description {
        font-size: $product-name-font-size - 1;
        @extend .hide;
    }
    .price {
        // all page
        color: $product-price-color;
        font-weight: $product-price-font-weight;
        font-size: $product-price-font-size;
        font-family: $product-price-font-family;
        margin-bottom: 10px;
        .price-old {
            color: $product-price-old-color;
            font-weight: $product-price-old-font-weight;
            font-size: $product-price-old-font-size;
            padding: 0 5px;
            text-decoration: line-through;
        }
        .price-tax {
            display: none;
        }
        .price-new {
            color:$price-new;
        }
    }
    .rating {    
        padding-bottom: 5px;
    }
    
    .carousel-controls-v1 {
        width: 80px;
        height: 35px;
        position: absolute;
        bottom: 35%;
        left:45%;
        margin-left: -30px;
        .carousel-control {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            height: 35px;
            width: 35px;
            background: #fff;
            &:hover {
                background: $theme-color;
                border-color: $theme-color;
                color: #fff;
            }
        }
    }
}

.product-list { 
    .description {
        display: block;
    }
}

.product-list-sidebar {
    @extend .product-list;
    .product-layout {
        padding: 10px 0;
        border-bottom: 1px solid $border-color;
        .group-action,
        .product-label { 
            display: none;
        }
        .product-meta {
            padding-left: 8px !important;
        }
    }
}

/* Quick View */
.pav-quickview { 
    position: absolute;
    z-index: 10;
    border-color: $border-color;
    @include transition (all .35s ease 0s);
    border-radius:0 !important;
    &:hover, &:focus {
        background: $theme-color;
        color: $white;
    }               
}
.product-list {
    .product-thumb {
        border-bottom: 1px solid $border-color;
        padding-bottom: 30px;
        margin-bottom: 30px;
        overflow: hidden;
        display: flex;
        align-items: center;
        .product-meta {
            text-align: left;
            width: 60%;
            padding: 0;
            padding-left: 30px;
        }
        .image {
            margin-bottom: 0;
            width: 40%;
        }
        .btn-cart {
            width: auto;
            position: relative;
            margin: 0;
        }
    }
    &:last-child .product-thumb {
        border: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
.product-latest, .product-featured, .product-listing-tabs {
    padding-top: 50px;
    .panel-heading{
        position: relative;
        padding-bottom: 50px;
        &:after{
            background: transparent url("#{$image-theme-path}icon1.png") no-repeat scroll 0 0;
            top: 60px;
            content: "";
            width: 30px;
            height: 15px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
        }
    } 
}
.product-latest {
    border-bottom: 1px solid $border-color;    
}

.product-grid.col-md-3 {
    .product-thumb .name {
        min-height: 40px;
    }
}

.rating {
    padding-bottom: 5px;
    .fa-stack {
        .fa-star {
            color: $brand-warning;
            + .fa-star-o{
                display: none;
            }
        }
        .fa-star-o{
            color:$gray-light;
        }
        &:hover{
            cursor: pointer;
        }
    }
}

.fa-stack {
    width: 1em !important; height:1em !important; line-height: 1em !important;
    .fa-stack-2x {
        font-size: 1em !important;
    }
}

.rating-wrapper {
    .fa-star{
        display: none;
        color: $brand-warning;
    }

    .fa-star-o{
        color:$gray-light;
    }
    .fa-stack {
        &.active,
        &.selected{
            .fa-star{
                display: block;
            }
            .fa-star-o { display:none}
        }
    }
}

/*** PavProductCarousel ***/


/** product-listing-tabs **/
.product-listing-tabs {
    .tab-heading {
        text-align: center;
        .nav-tabs {
            border: none;
            li {
                float: inherit;
                display: inline-block;
                padding-bottom: 10px;
                a {
                    text-transform: uppercase;
                    font-size: $font-size-base + 2;
                    border: 1px solid $border-color;
                    color: $theme-color;
                    font-weight: 600;
                    margin: 0px;
                    @include transition (all .35s ease 0s);
                }
                &.active, 
                &:hover,
                &:focus {
                    a{ 
                        background-color: $theme-color;
                        color: $white;
                    }
                }                   
            }
        }
    }
}


/** Label **/

.product-label-percentage, 
.product-label-special,
.product-label-discount {
    color: $product-label-color;
    font-size: $product-label-font-size;
    font-family: $product-label-font-family;
    font-weight: $product-label-font-weight;
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 10;
    line-height: $product-label-line-height;
    background: $label-sale-bg;
    text-align:center;
    letter-spacing: 2px;
    text-transform: $label-transform;
    @include border-radius(50%); 
    @include size(70px, 70px);
    span {
        border-top: 1px solid $product-label-color;
        border-bottom: 1px solid $product-label-color;
        padding: 0 2px;
    }
}
.product-label-discount { display:none;}
.product-label-special + .product-label-discount {
    right: 100px;
    background: #44cc9e;
    color: $white;
    span {
        border-color: $white;
    }
}

.product-nav-item {
    position:fixed;
    top:40%;
    z-index: 1000;
    .btn {
        width: 30px;
        height: 83px;
        padding: 0;
        z-index: 1000;
        position: relative;
        span {
            font-family: $font-icon;
            font-size: 30px;
        }
    }
    img { 
        float:left;
        width: 60px;
    }
    .price {
        color: $theme-color;
    }
    .dropdown {
        .dropdown-menu { 
            min-width: 300px; 
            display: block;
            visibility: hidden;
            z-index: 100;
            margin: 0;     
             @include box-shadow(none)  ;       
            @include transition(all 0.35s ease 0s);
        }
        &:hover {
            .dropdown-menu { 
                visibility: visible;
            }
        }
    }
    &.product-next {
        right: 0;
        .dropdown {
            .dropdown-menu {
                right: 30px;
                top:0px;
                left: auto;                 
                @include transform(translate3d(300px, 0, 0));        
            }
            &:hover {
                .dropdown-menu { 
                    @include transform(translate3d(0px, 0, 0));
                }
            }
        }
        .btn span {            
            &:before {
                content: "\f104";
            }
        }
    }
    &.product-prev {
        left: 0;
        .dropdown {
            .dropdown-menu {
                left: 30px;
                top:0px;   
                @include transform(translate3d(-300px, 0, 0));                
            }
            &:hover {
                .dropdown-menu { 
                    @include transform(translate3d(0px, 0, 0));
                }
            }
        }
        .btn span {            
            &:before {
                content: "\f105";
            }
        }
    }
}
/** product block style **/

.product-image-swap {
    position:relative;
    overflow: hidden;
    img {
        height: auto;
        max-width: 100%;
    }
    .image-hover { 
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;

    }
    .image-effect {
        top:0;position:absolute;
        overflow:hidden;
        left:0;
        right:auto;
        opacity:1;
        filter:alpha(opacity=100);
        -webkit-transform:translate(-100%,0);
        -ms-transform:translate(-100%,0);
        -o-transform:translate(-100%,0);
        transform:translate(-100%,0);
        -webkit-transition:all .3s;
        -o-transition:all .3s;
        transition:all .3s
    }
    &:hover .image-effect {-webkit-transform:translate(0,0);-ms-transform:translate(0,0);-o-transform:translate(0,0);transform:translate(0,0)}
    &:hover .image-hover {-webkit-transform:translate(100%,0);-ms-transform:translate(100%,0);-o-transform:translate(100%,0);transform:translate(100%,0);opacity:0;visibility:visible;z-index:-1}
}    

.product-image-slide {
    .carousel-controls-v1 {
        @include opacity(0);
        @include transition(all .3s);
    }
    &:hover {
        .carousel-controls-v1 {
            @include opacity(1);
        }
    }
}